<script setup>
const props = defineProps(["items"]);
const emit = defineEmits(["select", "goback"]);

function handleSelectChapter(chapterNum) {
  emit("select", { chapterNum });
}
</script>
<template>
  <div>
    <v-navigation-header
      class="mx-2 mb-4 sticky"
      @goback="emit('goback')"
      :label="transl('Select a section')"
    ></v-navigation-header>

    <div class="buttons-position mx-4">
      <div class="buttons-container">
        <v-btn
          v-for="chapterNum in items"
          @click="handleSelectChapter(chapterNum)"
          :value="chapterNum"
          class="button"
        >
          {{ chapterNum }}
        </v-btn>
      </div>
    </div>
  </div>
</template>
<style scoped>
.button {
  border-radius: 8px;
  border: 2px solid #bbc2d4;
  font-size: 19px;
  min-width: 40px;
  min-height: 40px;
  text-align: center;
  line-height: 37px;
  margin: 5px 5px;
  box-sizing: border-box;
}

.button:hover {
  background-color: #e4e4e4;
}

.buttons-container {
  grid-template-columns: repeat(auto-fill, minmax(40px, 1fr));
  display: grid;

  gap: 8px;
}

/* .buttons-container .active {
  background-color: var(--root-green);;
  color: var(--root-white);
  border: none;
} */
</style>
